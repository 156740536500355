<template>
    <li>
        <NuxtLink :to="localePath(item.url)">
            <div
                class="relative mb-2 flex flex-col items-center justify-center rounded-lg border-2 border-grey-50 bg-grey-100 py-2"
                :class="{
                    'border-yellow-100 !bg-bigg-yellow': checkActiveItem(item),
                }"
            >
                <img
                    :src="`/assets/icons/leftsidebar4/${checkActiveItem(item) ? item.key + '_active' : item.key}.png`"
                    :alt="`icon for ${item.name}`"
                    loading="eager"
                />
                <span
                    class="z-10 w-full text-center text-[9px] font-bold tracking-tighter text-grey-900"
                    >{{ $t(item.i18_key) }}</span
                >
            </div>
        </NuxtLink>
    </li>
</template>
<script setup>
defineProps({
    item: {
        type: Object,
        default: () => ({
            name: 'sports',
            i18_key: 'sports',
            id: 'sports',
            url: '/sports',
            icon: 'sports',
        }),
        required: true,
    },
})
const localePath = useLocalePath()
const checkActiveItem = (item) => {
    const route = useRoute()
    return (
        (item.name === 'live_casino' && route.fullPath.includes('casino')) ||
        route.fullPath === item.url ||
        (item.name === 'games' &&
            ['tables', 'instant', 'ingame'].includes(String(route.params.type)))
    )
}
</script>
<style lang="scss" scoped>
.slide-bar-item {
    height: 72px;
    span {
        color: #757575;
    }
}

.left-side-bar-active {
    span {
        color: #000000;
    }
}
</style>
