<template>
    <div class="w-full">
        <ul>
            <template v-for="item in sideNavItems" :key="item">
                <layout-mobile-side-navbar-4-item :item="item" />
            </template>
        </ul>
    </div>
</template>
<script setup>
const staticUrl = useRuntimeConfig().public.staticUrl
const sideNavItems = [
    {
        name: 'sports',
        i18_key: 'sidebar.sports',
        key: 'sports',
        id: 'sports',
        url: '/sports',
        icon: `${staticUrl}/assets/icons/leftsidebar4/sports.svg`,
    },
    {
        name: 'slots',
        i18_key: 'sidebar.slot',
        key: 'slots',
        id: 'slots',
        url: '/games/slots?partner=',
        icon: `${staticUrl}/assets/icons/leftsidebar4/slot.svg`,
    },
    {
        name: 'live_casino',
        i18_key: 'sidebar.live_casino',
        key: 'live_casino',
        id: 'live_casino',
        url: '/casino/all?partner=',
        icon: `${staticUrl}/assets/icons/leftsidebar4/livecasino.svg`,
    },
    {
        name: 'virtual_sport',
        i18_key: 'sidebar.virtual_sport',
        key: 'virtual_sport',
        id: 'virtual_sport',
        url: '/virtual-sport',
        icon: `${staticUrl}/assets/icons/leftsidebar4/virtual-sport.svg`,
    },

    {
        name: 'fishing',
        i18_key: 'sidebar.fishing',
        key: 'fishing',
        id: 'fishing',
        url: '/games/fishing?partner=',
        icon: `${staticUrl}/assets/icons/leftsidebar4/fishing.svg`,
    },

    {
        name: 'table_game',
        i18_key: 'sidebar.tables',
        key: 'table_game',
        id: 'table_game',
        url: '/games/tables?partner=',
        icon: `${staticUrl}/assets/icons/leftsidebar4/casino.svg`,
    },

    {
        name: 'lotto',
        i18_key: 'sidebar.lottery',
        key: 'lotto',
        id: 'lotto',
        url: '/games/lottery?partner=',
        icon: `${staticUrl}/assets/icons/leftsidebar4/lotto.svg`,
    },
    {
        name: 'quick game',
        i18_key: 'sidebar.quick_game',
        key: 'quick_game',
        id: 'quick_game',
        url: '/games/instants?partner=',
        title: 'Ingame',
        icon: `${staticUrl}/assets/icons/leftsidebar4/quick_game.svg`,
    },
    {
        name: 'cockfight',
        i18_key: 'header.nav_menu.cockfight',
        key: 'cockfight',
        id: 'cockfight',
        url: '/games/cockfight?partner=',
        title: 'Cockfight',
        icon: `${staticUrl}/assets/icons/leftsidebar4/slot.svg`,
    },
    {
        name: 'esports',
        i18_key: 'sidebar.esports',
        key: 'esports',
        id: 'esports',
        url: '/e-sports',
        icon: `${staticUrl}/assets/icons/leftsidebar4/esports.svg`,
    },
]
</script>
<style lang="scss" scoped>
.left-side-bar {
    width: 100%;
    &.active {
        img {
            filter: grayscale(1) contrast(100) brightness(1);
            mix-blend-mode: multiply;
        }
        a {
            background: linear-gradient(180deg, #fffbf0 0%, #fff1cb 100%);
            border: 1px solid #ffd2554f;
        }
    }
}
</style>
